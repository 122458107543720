import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import { firebaseCall } from '../CONSTS/ENDPOINTS';
import {
  addDoc,
  collection,
  doc,
  Firestore,
  getFirestore,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

interface useCompareServicesProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  products: any[];
}

const functions = getFunctions();
const database = getFirestore();
const auth = getAuth();

const useCompareServices = () => {
  const compareServices = async (searchTerm: string, products: never[]) => {
    try {
      console.log('comparing', products);

      const docRef = doc(database, `users/${auth.currentUser?.uid}`); // Reference to the user document
      const compareProductImagesRef = collection(
        docRef,
        'compareProductImages',
      ); // Reference to the subcollection

      // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
      const saveItem = await addDoc(compareProductImagesRef, {
        date: serverTimestamp(),
        'search term': searchTerm,
        'amazon products': products,
      });

      const search = httpsCallableFromURL(
        functions,
        firebaseCall('amazonProductImageSearchAndSave'),
      );

      const response = await search({
        products,
        compareProductImagesId: saveItem?.id,
        region: 'GB',
      });
    } catch (err) {
      console.error('Error comparing services', err);
    }
  };
  return { compareServices };
};

export default useCompareServices;
