/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import AuthLayout from './layouts/AuthLayout';
import LandingPage from './pages/Landing/LandingPage';
import LoginPage from './pages/Landing/LoginPage';
import RegisterPage from './pages/Landing/RegisterPage';
import './config/firebase';
import useIsLoggedIn from './utils/hooks/useIsLoggedin';
import DashboardLandingPage from './pages/dashboard/DashboardLandingPage';
import DashboardLayout from './layouts/DashboardLayout';
import Loading from './components/Loading';
import useSyncUser from './utils/hooks/useUser';
import ReportLanding from './pages/dashboard/ReportLanding';
import ReportEditor from './pages/report/ReportEditor';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import useThemeProvider from './theme';
import ReportPreview from './pages/report/ReportPreview';
import UsersLanding from './pages/user/UsersLanding';
import LoggedInAppLayout from './layouts/LoggedInAppLayout';
import UserDetail from './pages/user/UserDetail';
import { useUser } from './stateManagement/userStateManager';
import SuperAdminDashboardLanding from './pages/dashboard/SuperAdminDashboardLanding';
import SuperAdminDashboardLayout from './layouts/SuperAdminDashboardLayout';
import CreateUserSA from './pages/superAdmin/CreateUserSA';
import EditUserSA from './pages/superAdmin/EditUserSA';
import ManageUsers from './pages/superAdmin/ManageUsersSA';
import AEAuthCode from './pages/aEAuthCode/AEAuthCode';

function App(): JSX.Element | null {
  const isLoggedIn = useIsLoggedIn();

  useSyncUser();

  const theme = useThemeProvider();

  const { permissionStatus } = useUser();

  console.log('permissionStatus', permissionStatus);

  if (isLoggedIn === undefined) return null;
  console.log('isLoggedInisLoggedIn', isLoggedIn);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isLoggedIn === undefined && (
          <Route path="/" element={<Loading size={100} />} />
        )}
        <Routes>
          {isLoggedIn === false && (
            <Route path="/" element={<AuthLayout />}>
              <Route index element={<LandingPage />} />
              <Route path="login" element={<LoginPage />} />
              <Route path="register" element={<RegisterPage />} />
            </Route>
          )}
          {isLoggedIn === true && (
            <Route path="/" element={<LoggedInAppLayout />}>
              <Route element={<DashboardLayout />}>
                <Route index element={<DashboardLandingPage />} />
                <Route path="users" element={<UsersLanding />} />
                <Route path="user/:id" element={<UserDetail />} />
                <Route path="ae-autorisation" element={<AEAuthCode />} />
              </Route>
              <Route path="aEAuthCode" element={<AEAuthCode />} />

              <Route path="report" element={<ReportLanding />} />
              <Route path="report/:id" element={<ReportLanding />} />
              <Route path="report/:id/edit" element={<ReportEditor />} />
              <Route path="report/:id/preview" element={<ReportPreview />} />
            </Route>
          )}
          {isLoggedIn === true && permissionStatus.superAdmin && (
            <Route path="/" element={<LoggedInAppLayout />}>
              <Route element={<SuperAdminDashboardLayout />}>
                <Route index element={<SuperAdminDashboardLanding />} />
                <Route path="createUser" Component={CreateUserSA} />
                <Route path="user/edit/:id" Component={EditUserSA} />
                <Route path="user/manageUsers" Component={ManageUsers} />
              </Route>
            </Route>
          )}
          <Route path="*" element={<h2>no page</h2>} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
